<template>
  <app-modal title="Organization owner invitation" @submit="onSubmit">
    <app-form-input-text
      v-model="form.invitee_email"
      label="Email"
      required
      type="email"
    />

    <app-form-input-text
      v-model="form.invitee_first_name"
      label="First name"
      required
    />

    <app-form-input-text
      v-model="form.invitee_last_name"
      label="Last name"
      required
    />
  </app-modal>
</template>

<script lang="ts" setup>
const form = reactive<
  Pick<
    DatabaseInsertRequest<"organization_invitations">,
    "invitee_email" | "invitee_first_name" | "invitee_last_name" | "role"
  >
>({
  invitee_email: "",
  invitee_first_name: "",
  invitee_last_name: "",
  role: "OWNER",
});

function onSubmit() {
  return useModal("organizationOwnerInvitationDialog").close({
    payload: form,
    confirmed: true,
  });
}
</script>
